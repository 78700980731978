import React from 'react';

class HubspotChatWrapper extends React.Component {
  componentDidMount() {
    document.body.addEventListener('click', this.handleIntercomLinkClick);
  }

  componentWillUnmount() {
    document.body.removeEventListener('click', this.handleIntercomLinkClick);
  }

  handleIntercomLinkClick = event => {
    const link = event.target.closest('a');

    if (link && link.getAttribute('href') === 'https://intercom') {
      event.preventDefault();
      this.openHubspotChat();
    }
  };

  openHubspotChat() {
    if (window.HubSpotConversations) {
      window.HubSpotConversations.widget.open();
    } else {
      console.error('Hubspot Conversations API is not available.');
    }
  }

  render() {
    return this.props.children;
  }
}

export const wrapRootElement = ({ element }) => <HubspotChatWrapper>{element}</HubspotChatWrapper>;
